@use '@angular/material' as mat;
@use 'sass:map';


// Media queries
// TODO(josephperrott): Change $mat-xsmall and $mat-small usages to rely on BreakpointObserver,
$xsmall: 'max-width: 599px';
$small: 'max-width: 959px';

// TODO: Revisit all z-indices before beta
// z-index main list

$z-index-fab: 20 !default;
$z-index-drawer: 100 !default;

// Global constants
$pi: 3.14159265;

// Padding between input toggles and their labels
$toggle-padding: 8px !default;
// Width and height of input toggles
$toggle-size: 20px !default;

// Easing Curves
// TODO(jelbourn): all of these need to be revisited

// The default animation curves used by material design.
$linear-out-slow-in-timing-function: cubic-bezier(0, 0, 0.2, 0.1) !default;
$fast-out-slow-in-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !default;
$fast-out-linear-in-timing-function: cubic-bezier(0.4, 0, 1, 1) !default;

$ease-in-out-curve-function: cubic-bezier(0.35, 0, 0.25, 1) !default;

$swift-ease-out-duration: 400ms !default;
$swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;
$swift-ease-out: all $swift-ease-out-duration $swift-ease-out-timing-function !default;

$swift-ease-in-duration: 300ms !default;
$swift-ease-in-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2) !default;
$swift-ease-in: all $swift-ease-in-duration $swift-ease-in-timing-function !default;

$swift-ease-in-out-duration: 500ms !default;
$swift-ease-in-out-timing-function: $ease-in-out-curve-function !default;
$swift-ease-in-out: all $swift-ease-in-out-duration $swift-ease-in-out-timing-function !default;

$swift-linear-duration: 80ms !default;
$swift-linear-timing-function: linear !default;
$swift-linear: all $swift-linear-duration $swift-linear-timing-function !default;


//$sidenav-background: #1a202e !default;
$sidenav-background: #e5e5e5;

$config: mat.define-typography-config(
  $font-family: var(--font),
  $headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $headline-4: mat.define-typography-level(34px, 40px, 400),
  $headline-5: mat.define-typography-level(24px, 32px, 400, $letter-spacing: -0.019em),
  $headline-6: mat.define-typography-level(18px, 26px, 500, $letter-spacing: -0.014em),
  $subtitle-1: mat.define-typography-level(16px, 28px, 400, $letter-spacing: -0.011em),
  $subtitle-2: mat.define-typography-level(14px, 24px, 500, $letter-spacing: -0.006em),
  $body-1: mat.define-typography-level(15px, 24px, 400, $letter-spacing: -0.009em),
  $body-2: mat.define-typography-level(14px, 20px, 400, $letter-spacing: -0.006em),
  $caption: mat.define-typography-level(12px, 20px, 400, $letter-spacing: 0),
  $button: mat.define-typography-level(14px, 14px, 500, $letter-spacing: -0.006em)
) !default;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$vex-primary: mat.define-palette((
  100: var(--color-primary-rgb),
  500: var(--color-primary-rgb),
  700: var(--color-primary-rgb),
  contrast: (
    100: var(--color-primary-contrast-rgb),
    500: var(--color-primary-contrast-rgb),
    700: var(--color-primary-contrast-rgb)
  )
)) !default;

$vex-accent: mat.define-palette((
  100: var(--color-accent-rgb),
  500: var(--color-accent-rgb),
  700: var(--color-accent-rgb),
  contrast: (
    100: var(--color-accent-contrast-rgb),
    500: var(--color-accent-contrast-rgb),
    700: var(--color-accent-contrast-rgb),
  )
)) !default;

// The warn palette is optional (defaults to red).
$vex-warn: mat.define-palette((
  100: var(--color-warn-rgb),
  500: var(--color-warn-rgb),
  700: var(--color-warn-rgb),
  contrast: (
    100: var(--color-warn-contrast-rgb),
    500: var(--color-warn-contrast-rgb),
    700: var(--color-warn-contrast-rgb),
  )
)) !default;

$vex-theme-foreground: (
  text: black,
  elevation: #000,
  divider: rgba(82, 63, 105, 0.06),
  dividers: rgba(82, 63, 105, 0.06)
) !default;

$vex-theme-background: (
  app-bar: #ebebee
) !default;

// Create the theme object (a Sass map containing all of the palettes).
$vex-theme: mat.define-light-theme((
  color: (
    primary: $vex-primary,
    accent: $vex-accent,
    warn: $vex-warn,
    is-dark: false
  ),
  typography: $config,
  density: 0
)) !default;

$vex-theme: map.deep-merge($vex-theme, (
  color: (
    foreground: map_merge(mat.$light-theme-foreground-palette, $vex-theme-foreground),
    background: map_merge(mat.$light-theme-background-palette, $vex-theme-background),
  ),
  // Required for @angular/material legacy compatibility
  foreground: map_merge(mat.$light-theme-foreground-palette, $vex-theme-foreground),
  background: map_merge(mat.$light-theme-background-palette, $vex-theme-background),
));

$vex-dark-theme: mat.define-dark-theme((
  color: (
    primary: $vex-primary,
    accent: $vex-accent,
    warn: $vex-warn,
    is-dark: true
  ),
)) !default;

$vex-dark-theme-foreground: () !default;

$vex-dark-theme-background: (
  app-bar: darken($sidenav-background, 5),
  background: lighten($sidenav-background, 5),
  card: $sidenav-background,
  dialog: $sidenav-background,
  status-bar: darken($sidenav-background, 5)
) !default;

$vex-dark-theme: map.deep-merge($vex-dark-theme, (
  color: (
    foreground: map_merge(mat.$dark-theme-foreground-palette, $vex-dark-theme-foreground),
    background: map_merge(mat.$dark-theme-background-palette, $vex-dark-theme-background),
  ),
  // Required for @angular/material legacy compatibility
  foreground: map_merge(mat.$dark-theme-foreground-palette, $vex-dark-theme-foreground),
  background: map_merge(mat.$dark-theme-background-palette, $vex-dark-theme-background),
));

$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
