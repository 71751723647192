.mat-mdc-icon-button .mat-mdc-button-wrapper > *,
.mat-menu-item .mat-icon,
.mat-button .mat-icon {
  vertical-align: middle !important;
}

.mat-mdc-table .mat-mdc-cell,
.mat-table .mat-mdc-header-cell {
  // box-sizing: content-box;
  white-space: nowrap;
}

.mat-mdc-select-panel {
  @apply text-base;
}

.mat-drawer-side {
  border: 0;
}
