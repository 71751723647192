@import "simplebar/dist/simplebar.css";
@import "highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";

:root {
  --sidenav-item-color: #ffd740;
  --color-primary: #ffd740;


  --mdc-theme-primary: #ffd740;
  --mdc-theme-secondary: #a6192e;

  --mdc-filled-button-container-color: rgb(182, 190, 64);
  --mdc-filled-button-container-color: rgb(255, 255, 255);

  // #Item
  //--sidenav-item-color-active: #bd9b60;
  //--sidenav-item-icon-color: #bd9b60;
  --sidenav-item-color-hover: #ffd740;
  --sidenav-item-background-hover: #ffd740;

  //#Item icon
  --sidenav-item-icon-color-active: #ffd740;
  --sidenav-item-icon-color-hover: #ffd740;

  --mdc-typography-body1-font-size: 14px;
  --mdc-typography-subtitle2-font-size: 12px;
  --font-size: 14px;
  --foreground-divider:#523f690f;

  --color-accent-rgb: #ffd740;
  //--mdc-icon-button-icon-color: #eac437;
  --mdc-icon-button-icon-color: #313129;

  --color-accent-contrast-rgb: rgb(0, 0, 0);
  --mat-tab-header-active-label-text-color: #000000;
  //ffd740




}

.iap-form-footer {
  @apply p-4;
  @apply p-4;
  @apply flex;
  @apply flex-row-reverse;
  background: #ececec;
  border-top: 1px solid rgb(212, 212, 212);
  gap: 1rem;
}

.iap-form-footer-card {
  @apply rounded shadow bg-foreground;
  @apply p-3;
  @apply flex;
  @apply flex-row-reverse;
  @apply mt-3;
  @apply gap-1;
  @screen sm {
    @apply gap-2;
  }
  background: #ececec;
  border-top: 1px solid rgb(212, 212, 212);
}
.filter-section {
  @apply text-xs;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.mat-header-cell {
  color: #000000;
}

table .mdc-data-table__cell, table .mdc-data-table__header-cell {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #523f690f;
  padding: 0 var(--cell-padding);
  &.mat-column-itemName {
    max-width: 330px;
  }
}
.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: #000000 !important;
}
.mat-mdc-form-field {
  flex-shrink: 0;
}
.mat-datepicker-content {
  background-color: #FFF;
  @apply shadow-lg;
}

.mdc-icon-button.hidden {
  display: none;
}

.px-gutter {
  background: #fff;
  overflow-x: auto;
}

.sub-header {
  margin-right: 15px;
  padding-right: 15px;
  text-transform: uppercase;
}

main.content {
  .sub-header-toolbar.mat-accent {
    position: sticky;
    top: var(--toolbar-height);
    z-index: 5;
  }
}

.sub-header-toolbar {
  overflow-x: auto;
  overflow-y: hidden;

  & + .mat-toolbar-single-row {
    z-index: 5;
    top: calc( var(--toolbar-height) * 2 );
    position: sticky;

    height: 78px;
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.sub-header-button {
  height:28px !important;
  font-size: 12px;
  min-width: 64px;
  line-height: 28px !important;
  color: #000000;
  @media only screen and (max-width: 599.88px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    min-width: 2rem !important;
  }
  @media only screen and (max-width: 959.88px) {
    & > .mat-icon {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    & > .mdc-button__label {
      display: none;
    }
  }
}

.app-notification-detail {
  display: flex;
  flex-direction: column;
  height: 100%;
  & > mat-dialog-content.mat-mdc-dialog-content {
    flex-grow: 1;
    overflow: visible;
    max-height: none;
    padding: 0;
    & > mat-tab-group {
      height: 100%;
      & > .mat-mdc-tab-body-wrapper {
        flex-grow: 1;
        .mat-mdc-tab-body-content {
          height: auto;
          overflow: visible;
          & > :first-child {
            & > .column {
              margin-top: 0;
            }
          }
          & > :last-child {
            & > .column {
              margin-bottom: 0;
            }
          }
          .column {
            word-break: break-all;
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
  }
}

.mat-calendar {
  .mat-calendar-table {
    .mat-calendar-body-today {
      border-width: 2px;
      border-color: var(--color-primary);
    }
  }
}

.text-danger {
  color: #e74242;
}
.font-weight-bold {
  font-weight: bold;
}
